

.comment{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
}
.comment:nth-child(2) {
    color: #FFF;

font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.comment:nth-child(3) {
    color: #FFF;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.comment img{
    width: 200px;
    height: 200px;
    border-radius: 100px;
    
    
}

.next , .prev{
    display: flex;
    
    
    justify-content: center;
    align-items: center;
    background-color: #28225E;
    color: #28225E;
    
    
}

/* .next  button, .prev button{
    color: #28225E;
} */


.button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: #28225E;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }
  
  .button:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }
  
  .button:hover .icon {
    transform: translate(4px);
  }
  
  .button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }
  
  .button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
  
  @keyframes shine {
    0% {
      left: -100px;
    }
  
    60% {
      left: 100%;
    }
  
    to {
      left: 100%;
    }
  }
  


   /* slider css */

   .slider {
    display: flex;
    justify-content: center;
    background: #28225E;
    padding: 20px;
  
  }
  
  .image {
    width: 200px;
    height: 200px;
    border-radius: 100px;
    
  
  }
  
 .imga{
    display: flex;
    justify-content: center;
 }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  .pclass{
    color: #FFF;

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .pcomments{
    color: #FFF;

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }