.FieldServiceParent {
  display: flex;
  flex-direction: column;
}

.FieldServiceParent div img  {
width: 100%;
}

.child {
  display: flex;
  flex-direction: column;
  padding: 0 120px 32px 120px;

  & .title {
    color: #000;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 60px */
    text-align: center;
    margin: 24px;
    color: #ed3e23;
  }

  & .desc {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: 0.4px;
  }

  & .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    

    & .card {
      flex: 0 0 100%;
      display: flex;
      width: 582px;
      padding: 16px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #28225e;
      box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 10px;
      justify-content: space-evenly;
      align-items: center;
    
      
    }

    & .cardImg {
      width: 135px;
      height: 135px;
      flex-shrink: 0;
      border: 2px solid yellow;
      background-color: #fff;
      border-radius: 50%;
      margin: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    & .cardData {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0  70%;

      & .cardHeading {
        color: #ed3e23;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      & .cardDesc {
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        margin-top: 11px;
      }
    }
  }
}

.cardsForMax {
  display: flex;
  justify-content: space-around;

  & .cardForMax {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
    margin: 10px;
    padding: 16px;

    & .cardImg {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin: 8px 0;
    }

    & .cardHeading {
      color: #111013; 
      text-align: center;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin:  8px 0;
    }

    & .cardDesc {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 16x;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    }
  }
}

.title11 {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 24px 0;
  color: #111013;
  font-weight: 600;
}

/* For devices up to 767px */
@media only screen and (max-width: 767px) {
  .child {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    & .title {
      color: #000;
      font-family: Roboto;
      font-size: 24px;
    }

    & .desc {
      font-size: 16px;
    }

    & .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & .card {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        width: 582px;

        flex-shrink: 0;
        border-radius: 10px;
        background: #28225e;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 10px;
        justify-content: space-evenly;
        align-items: center;
      }

      & .cardImg {
        width: 135px;
        height: 135px;
        flex-shrink: 0;
        border: 2px solid yellow;
        background-color: #fff;
        border-radius: 50%;
        margin: 23px;
      }

      & .cardData {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .cardHeading {
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        & .cardDesc {
          color: #fff;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          margin-top: 11px;
        }
      }
    }
  }

  .cardsForMax {
    display: flex;
    justify-content: center;
    flex-direction: column;

    align-items: center;

    & .cardForMax {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 300px;
      height: 350px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.25);
      margin: 10px;
      padding: 16px;

      & .cardImg {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
      }

      & .cardHeading {
        color: #ed3e23;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      & .cardDesc {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
      }
    }
  }

  .title11 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 24px 0;
    color: #111013;
    font-weight: 600;
  }
}


.jptitle{
  font-weight: 600;
}