.parentContainer {
  display: flex;
  padding: 24px;
}

.child1 {
  flex: 1 0 65%;

  & .child1A {
    

    & .child1Ai {
      display: flex;
      justify-content: space-between;
      border: 2px solid red;

      & .title {
        color: #28225e;
        font-family: Inter;
        font-size: 96px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
        margin: 24px;
      }

      & img{
        margin-top:32px ;
        box-shadow: 30px 30px;
        
        object-fit: cover;
      }

    
    }

    & p{
        color: #000;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 36px */
padding: 24px;
    }
  }

  & .child1B{

    margin-bottom: 24px;
    text-align: justify;
    padding: 24px;
    & .title{
        color: #000;
font-family: Roboto;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 48px */
margin-bottom: 24px;
    }

    & .desc{
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */ 
        
    }
  }
}

.child2 {
  flex: 1 0 35%;
}











.div {
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  gap: 20px;
  padding: 0 39px 0 10px;
}
@media (max-width: 991px) {
  .div {
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 20px;
  }
}
.div-2 {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  background-color: #28225e;
  align-self: stretch;
  margin-top: 5px;
  flex-grow: 1;
  padding: 21px 24px;
  font: 600 24px/130% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .div-2 {
    white-space: initial;
    padding: 0 20px;
  }
}
.div-3 {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: auto 0;
  font: 600 24px/130% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-4 {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin: auto 0;
  font: 600 24px/130% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.div-5 {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  align-self: center;
  flex-grow: 1;
  white-space: nowrap;
  margin: auto 0;
  font: 600 24px/130% Roboto, -apple-system, Roboto, Helvetica,
    sans-serif;
}
@media (max-width: 991px) {
  .div-5 {
    white-space: initial;
  }
}
