.parent {
  display: flex;
  flex-direction: column;
  padding: 0 128px;

  & .child1 {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-top: 50px;

    & .header {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 60px */
      margin-bottom: 50px;
    }

    & .info {
      display: flex;
      flex: 1 0 45%;
      & .desc {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        padding-right: 16px;
      }

      & .image {
        flex: 1 0 45%;
      }
    }
  }

  & .child2 {
    display: flex;
    flex-direction: column;
    

    & .heading {
      color: #000;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
      margin-bottom: 8px;
      margin-top: 64px;
    }

    & .note {
      color: #000;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 16px;

      & span {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
      }
    }
  }

  & .child3 {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
    

    & .heading {
      color: #000;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
      margin-bottom: 8px;
      margin-top: 64px;
    }

    & .note {
      color: #000;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 16px;

      & span {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
      }
    }
  }



  & .footer {
    display: flex;
    flex: wrap;
    margin: 50px 0;

    & p {
      flex: 1 0 80%;
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    & div {
      flex: 1 0 20%;
    }

    & a {
      border-radius: 30px;
      display: inline-flex;
      padding: 15px 34px;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background: #28225e;
      color: #fff;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
