@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.industryParent {
  display: flex;
  flex-direction: column;
  padding: 50px 128px;
}

.header,
.footer {
  padding: 16px 24px;
  font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;


}

.headertitle {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 0 0 24px 0;
  text-transform: capitalize;
}

.headerdesc,
.footerdesc {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  padding-bottom: 24px;
}

.leftTitle {
  color: #fff;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.leftDesc {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  
}

.leftText1 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/unsplash_9GwMIek9jnY.png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  
}

.leftText2 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/unsplash_9GwMIek9jnY\ \(6\).png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftText3 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/Building\ management\ system.jpg");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftText4 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/unsplash_9GwMIek9jnY\ \(7\).png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftData {
  display: flex;
  flex-direction: column;
  padding: 24px;
  
}

.leftChild {
  flex-basis: 50%;
}

.rightText1 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/unsplash_9GwMIek9jnY\ \(1\).png");
  /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
    background-size: contain; */
  background-position: left; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightText2 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/Automotive.jpg");
 
    background-size: contain;
  background-position: left; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightText3 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../media/industry/unsplash_9GwMIek9jnY\ \(8\).png");
  /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
    background-size: contain; */
  background-position: left; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightData {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.rightChild {
  flex-basis: 50%;
}

.rightTitle {
  color: #000;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.rightDesc {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

p {
  justify-content: center;
}

@media screen and (max-width: 768px) {

  .industryParent {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

    .rightTitle {
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }

      .leftTitle {
        color: #fff;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20px;
      }

  .leftText1 {
    background-color: #0c0b36;
    background-image: linear-gradient(
      225deg,
      #0c0b36 0%,
      #46107c 93%,
      #2b86c5 100%
    );

    background-size: contain;
    background-position: right; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .leftText2 {
    background-color: #0c0b36;
    background-image: linear-gradient(
      225deg,
      #0c0b36 0%,
      #46107c 93%,
      #2b86c5 100%
    );
    background-size: contain;
    background-position: right; /* Adjust this line to position the image on the right */
    min-height: 300px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .leftText3 {
    background-color: #0c0b36;
    background-image: linear-gradient(
      225deg,
      #0c0b36 0%,
      #46107c 93%,
      #2b86c5 100%
    );

    background-size: contain;
    background-position: right; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .leftText4 {
    background-color: #0c0b36;
    background-image: linear-gradient(
      225deg,
      #0c0b36 0%,
      #46107c 93%,
      #2b86c5 100%
    );

    background-size: contain;
    background-position: right; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .rightText1 {
    background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%);
    /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
      background-size: contain; */
    background-position: left; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .rightText2 {
    background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%);
    /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
      background-size: contain; */
    background-position: left; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .rightText3 {
    background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%);
    /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
      background-size: contain; */
    background-position: left; /* Adjust this line to position the image on the right */
    min-height: 400px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
  }

  .leftText1 .leftChild:nth-child(2) {
    display: none;
    border: 4px solid orange;
  }

  .leftText2 .leftChild:nth-child(2) {
    display: none;
    border: 4px solid orange;
  }

  .leftText3 .leftChild:nth-child(2) {
    display: none;
    border: 4px solid orange;
  }

  .leftText4 .leftChild:nth-child(2) {
    display: none;
    border: 4px solid orange;
  }

  .rightText1 .rightChild:nth-child(1) {
    display: none;
    border: 4px solid orange;
  }

  .rightText2 .rightChild:nth-child(1) {
    display: none;
    border: 4px solid orange;
  }

  .rightText3 .rightChild:nth-child(1) {
    display: none;
    border: 4px solid orange;
  }

  .leftChild {
    flex-basis: 100%;
  }

  .rightChild {
    flex-basis: 100%;
  }
}


.jptitle {
  
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    
  
}


.jptitle {
  
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  

}