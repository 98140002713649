.footerParent {
  display: flex;
  flex-direction: column;
  
  
}

.child1 {
  display: flex;
  background: rgba(40, 34, 94, 0.1);
  justify-content: space-around;
  padding: 8px;
  flex-wrap: wrap;
  justify-content: space-between;

  
  
}

.title {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.016px;
  
  
}
.child {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.014px;
  width: 250px;
  margin: 5px;
  
  

}

.child2 {
  background: #28225e;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 16px 0;
}

.child a {
  margin: 16px 0;
  
  display: block;
  text-align: left;
}

span img {
  width: 24px;
  padding:  2px;
  margin: 0 8px ;
}

.child p img {
  width: 100px;

}

.logos{
  
  display: flex;
 
  justify-content: center;
}


.socialLink{
  text-align: center;
  padding: 16px;
}

.mainLogo{
  display: flex;
  justify-content: center;
  align-items: center;
  
}


/* For devices up to 767px */
@media only screen and (max-width: 767px) {
  
  .child1 {
    display: flex;
    background: rgba(40, 34, 94, 0.1);
    justify-content:center;
    padding: 8px;
    flex-wrap: wrap;
    ;
    
    
  }

  .child {
 
  
    width:250px;
    margin: 5px;
    
  
  }
}


@media only screen and (max-width: 480px) {
  
  .child1 {
    display: flex;
    background: rgba(40, 34, 94, 0.1);
    justify-content:center;
    padding: 8px;
    flex-wrap: wrap;
    ;
    
    
  }

  .child {
 
  
    width: 100%;
    margin: 5px;
    
  
  }
}

