.industryParent {
  display: flex;
  flex-direction: column;
  padding: 50px 128px;
}

.header,
.footer {
  padding: 0 24px;
}

.headertitle {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding: 0 0 24px 0;
}

.headerdesc,
.footerdesc {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  padding-bottom: 24px;
}

.leftTitle {
  color: #fff;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.leftDesc {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.leftText1 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY.png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  min-height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  position: relative;
}

.leftText2 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY\ \(6\).png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftText3 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY\ \(7\).png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftText4 {
  background: linear-gradient(90deg, #28225e 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY.png");
  background-size: contain;
  background-position: right; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.leftData {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.leftChild {
  flex-basis: 50%;
  position: relative;

  & .btn {
    position: absolute;
    bottom: 50px;
    left: 150px;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.rightText1 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY\ \(1\).png");
  /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
    background-size: contain; */
  background-position: left; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightText2 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY\ \(7\).png");
  /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
    background-size: contain; */
  background-position: left; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightText3 {
  background: linear-gradient(-90deg, #fff 54%, rgba(40, 34, 94, 0) 100%),
    url("../../media/industry/unsplash_9GwMIek9jnY\ \(8\).png");
  /* background-image: url('../media//industry/unsplash_9GwMIek9jnY\ \(1\).png');
    background-size: contain; */
  background-position: left; /* Adjust this line to position the image on the right */
  height: 400px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
}

.rightData {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.rightChild {
    flex-basis: 50%;
  position: relative;

  & .btn {
    position: absolute;
    bottom: 50px;
    left: 150px;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.rightTitle {
  color: #000;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.rightDesc {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
