

/* Common styles for all screen sizes */
.transformerLifeCycleManagemParent {
    position: relative;
    background-color: #28225e;
    width: 100%;
    height: 200px;
    text-align: left;
    font-size: 24px;
    color: #fff;
    font-family: Inter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.transformerLifeCycle1 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
}

.zeroBreakdown {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

/* Media queries for responsive design */
/* For mobile screens */
@media only screen and (max-width: 600px) {
    .transformerLifeCycleManagemParent {
        height: 150px;
        font-size: 18px;
    }
    
    .transformerLifeCycle1 {
        font-size: 18px;
    }
    
    .zeroBreakdown {
        font-size: 14px;
    }
}

/* For tablets */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .transformerLifeCycleManagemParent {
        height: 180px;
    }
}

/* For desktop screens */
@media only screen and (min-width: 1025px) {
    /* Add any desktop-specific styles here */
}
