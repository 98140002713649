.parent {
  display: flex;
  flex-direction: column;
  /* padding: 0 128px; */
  justify-content: center;
  align-items: center;

  & .child1 {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-top: 50px;

    & .header {
      color: #ed3e23;
      text-align: center;
      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 60px */
      margin-bottom: 50px;
    }

    & .info {
      display: flex;
      /* flex: 1 0 45%; */
      gap: 32px;
      & .desc {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        /* padding-right: 16px; */

      }

      & .image {
        flex: 1 0 45%;
      }
    }
  }

  & .child2 {
    display: flex;
    flex-direction: column;

    & .heading {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */
      margin:  16px 0 ;

    }

    & .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      & .card {
        display: flex;
        flex-direction: column;
        width: 300px;
        background: #fff;
        box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
        min-height: 400px;
        flex-shrink: 0;
        margin: 15px;
        padding: 16px;

        & .cardimg {
          width: 100%;
          /* height: 200px; */
          flex-shrink: 0;

          & img {
            width: 100%;
          }
        }
        & h3 {
          color: #000;
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin: 10px 0;
        }

        & .cardDesc {
          color: #000;
          text-align: center;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
    }
  }

  & .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: wrap;
    margin: 50px 0;

    & p {
      flex: 1 0 80%;
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    & div {
      flex: 1 0 20%;
    }

    & a {
      border-radius: 30px;
      display: inline-flex;
      padding: 15px 34px;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background: #28225e;
      color: #fff;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

/* For devices up to 767px */
@media only screen and (max-width: 767px) {
  .parent {
    padding: 0 16px;

    & .child1 {
      display: flex;
      flex-direction: column;
      align-content: space-evenly;
      margin: 16px 0;

      & .header {
        font-size: 20px;
      }

      & .info {
        display: flex;
        flex-direction: column;
        flex: 1 0 45%;
        & .desc {
          order: 2;
        }

        & .image {
          flex: 1 0 45%;
          order: 0;
          margin: 8px 0;
        }
      }
    }

    & .child2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .heading {
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 36px */
      }

      & .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        & .card {
          display: flex;
          flex-direction: column;
          width: 300px;
          background: #fff;
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
          min-height: 400px;
          flex-shrink: 0;
          margin: 15px;
          padding: 16px;

          & .cardimg {
            width: 100%;
            height: 200px;
            flex-shrink: 0;

            & img {
              width: 100%;
             
            }
          }
          & h3 {
            color: #000;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 10px 0;
          }

          & .cardDesc {
            color: #000;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 24px */
          }
        }
      }
    }

    & .footer {
      display: flex;
      flex: wrap;
      margin: 50px 0;
      flex-direction: column;

      & p {
        flex: 1 0 80%;
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }

      & div {
        flex: 1 0 20%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & a {
        border-radius: 30px;
        display: inline-flex;
        padding: 15px 34px;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: #28225e;
        color: #fff;
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 16px;
      }
    }
  }
}



.jptitle{
  font-weight: 600;
}