.serviceParent{
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    justify-content: center;
    
}


@media (max-width: 600px) {
   .serviceParent{
    padding: 4px;
   }
  

}