
  
  .productImage {
    width: 100%;
    height: 10em;
    object-fit: cover;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 350px;
    margin: auto .5rem;
    display: flex;
    flex-direction: column;
    gap:16px;
    text-align: center;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 8px;
    min-height: 300px;
    font-weight: 600;
  }
  
  
