/* .parent div img {
  width: 100%;
} */


.parent {
  display: flex;
  flex-direction: column;
  /* padding: 0 128px 128px 128px; */
  max-width: 1280px;
 
  & .child1 {
    display: flex;
    flex-direction: column;
    /* align-content: space-evenly; */
    margin-top: 50px;

    & .header {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 60px */
      margin-bottom: 32px;
    }

    & .desc {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
      margin-bottom: 24px;
      text-align: center;
      font-weight: 600;
    }

    & .header2 {
      color: #ed3e23;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      margin-bottom: 24px;
    }

    & .desc2{
        color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 30px */
    }
  }

  & .child2 {
    display: flex;
    flex-direction: column;

    & .heading {
      color: #0f0e0e;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
      margin: 24px 0 ;
      font-weight: 600;

    }
    
      & .partners {
        display: flex;
        width: 100%;
    
        & .partner {
          flex-direction: column;
          
          margin: 8px;
          
          
          width: 100%;

    
          & img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            
            
          }
    
          
        }
      }
  }
}


& .child3{
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f1f1f5;
    /* margin-top: 110px; */
    justify-content: space-around;
    /* align-items: center; */

    & .subChild1{
        flex-basis: 50%;
        margin: 0 24px;

        & .title{
            color: #131111;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 24px;

        }

        & ul li{
            color: #0a0a0a;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
list-style-position: inside;
margin-bottom: 8px;


        }
    }

    & .subChild2{
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        
        & img{
            /* margin: 24px 0; */
        }
        
    }
}


.info{
    margin-top: 24px;
    & .header2 {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      & .desc2{
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
      }    
}


/* For devices up to 767px */
@media only screen and (max-width: 767px) {
  .parent {
    display: flex;
    flex-direction: column;
    padding: 16px;
  
    & .child1 {
      margin-top: 16px;
  
      & .header {
   
        font-size: 24px;
 
        margin: 0;
      }
  
      & .desc {

        font-size: 16px;

        margin-bottom: 8px;
      }
  
      & .header2 {
        font-size: 20px;
        margin-bottom: 8px;
      }
  
      & .desc2{
  font-size: 16px;

      }
    }
  
    & .child2 {
      display: flex;
      flex-direction: column;

  
      & .heading {
        margin: 8px 0 ;
      }
      
        & .partners {
          display: flex;
          flex-direction: column;
      
          & .partner {
            flex-direction: column;
            /* border: 2px solid #28225e;
            background: #28225e;
            margin-left: 8px; */
      
            & img {
              height: 70%;
            }
      
            & .name {
              height: 30%;
              border: 2px solid #28225e;
              background: #28225e;
              display: flex;
            
              padding-bottom: 10px;
              flex-direction: column;
              align-items: center;
              gap: 16px;
              color: #fff;
              font-family: Inter;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              place-items: center center;
              text-align: center;
            }
          }
        }
    }
  }
  
  
  & .child3{
      display: flex;
      flex-direction: column;
      padding: 8px;
  
      & .subChild1{
          flex-basis: 50%;
          margin: 0 24px;
          order: 2;
  
          & .title{
              color: #080808;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
  
          }
  
          & ul li{
              color: #131111;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-position: inside;
  margin-bottom: 8px;
  
  
          }
      }
  
      & .subChild2{
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          order: 1;
          
          & img{
              margin: 24px 0;
          }
          
      }
  }
  
  
  .info{
      margin-top: 24px;
      & .header2 {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
    
        & .desc2{
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }    
  }
  
}



