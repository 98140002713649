.parent {
  display: flex;
  flex-direction: column;
  /* padding: 0 128px; */
  justify-content: center;
  align-items: center;

  & .child1 {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-top: 50px;

    & .header {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 60px */
      margin-bottom: 50px;
      color: #ed3e23;
    }

    & .info {
      display: flex;
      /* flex: 1 0 45%; */
      gap: 32px;
      & .desc {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        /* padding-right: 16px; */
      }

      & .image {
        flex: 1 0 45%;
      }
    }
  }

  & .child2 {
    display: flex;
    flex-direction: column;

    & .heading {
      color: #000;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 36px */
      margin-bottom: 64px;
      margin-top: 64px;
      font-weight: 600;
      text-align: center;
    }

    & .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & .card {
        display: flex;
        flex-direction: column;
        width: 300px;
        background: #fff;
        box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
        min-height: auto;
        flex-shrink: 0;
        margin: 24px;
        padding: 16px;
        /* padding-top: 0px; */
        text-align: center;
        border-radius: 4px;


        & .header {
          display: flex;
          align-items: center;
        
          justify-content: center;
          margin-bottom: 0px;
    

   

          & .title {
            color: #000;
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 24px */
            position: relative;
            text-align: center;
            margin: 8px 0 ;
          }
        }

        & .desc {
          color: #000;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          margin-top: 0%;
        }
      }
    }
  }

  & .footer {
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: wrap;
    margin: 50px 0;

    & p {
      flex: 1 0 80%;
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }

    & div {
      flex: 1 0 20%;
    }

    & a {
      border-radius: 30px;
      display: inline-flex;
      padding: 15px 34px;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background: #28225e;
      color: #fff;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

/* For devices up to 767px */
@media only screen and (max-width: 767px) {
  .parent {
    padding: 0 16px;

    & .child1 {
      margin-top: 16px;

      & .header {
        font-size: 24px;
        margin: 0;
      }

      & .info {
        display: flex;
        flex: 1 0 45%;
        flex-direction: column;
        & .desc {
          order: 2;
        }

        & .image {
          flex: 1 0 45%;
          order: 1;
          margin: 16px 0;
        }
      }
    }

    & .child2 {
      display: flex;
      flex-direction: column;

      & .heading {
        margin: 24px;
        text-align: center;
      }
    }

    & .footer {
      flex-direction: column;

      & div {
        flex: 1 0 20%;
        display: flex;
        justify-content: center;
      }

      & a {
        margin: 16px;
      }
    }
  }
}


.jptitle{
  font-weight: 600;
}
