.automationParent {
  display: flex;
  margin: 24px;
}

 a{
  text-decoration: none;
  text-align: left;
 }

.child1 {
  flex: 1 0 20%;
  
  flex-direction: column;
  background: #FFF;
box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);

margin: 16px;

top: 100px;
  & .child1A{
    width: 100%;
    display: flex;
    text-align: left;
    


padding: 36px 13px 36px 30px;

align-items: center;
flex-shrink: 0;
background: #28225E;
margin-bottom: 8px;

& p {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.016px;
    
    
    text-align: left;
}
  }

  & .child1Active{

    width: 100%;
    display: flex;


padding: 36px 13px 36px 30px;
justify-content: flex-end;
align-items: center;
flex-shrink: 0;
background: #28225E;
margin-bottom: 8px;
border-left: 8px solid red;
text-align: left;

& p {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.016px;
   
}



  }

}




.child2 {
  flex: 1 0 80%;
  
}

.child2A {
  
  margin-bottom: 16px;

  & .heading {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 48px */
    letter-spacing: 0.032px;
    
  }

  & .desc {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.016px;
  }
}

.child2B {
  background: #28225e;
  display: flex;
  flex-direction: column;

  & .heading {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.02px;
    text-align: center;
  }

  & .card {
    
    display: flex;
    justify-content: space-between;
    & .cardChild {
      
      background: #fff;
      margin: 8px;

      & .title {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.016px;
        margin-bottom: 8px;
        
        
      }

      & .desc {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.016px;
      }
    }
  }
}

.child2C {
  background: #fff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;

  & .heading {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.02px;
  }

  & .card {
    
    display: flex;
    justify-content: space-between;
    & .cardChild {
      
      background: #28225e;
      margin: 8px;
      padding: 8px;

      & .title {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 24px */
        letter-spacing: 0.016px;
        margin-bottom: 8px;
      }

      & .desc {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: 0.016px;
      }
    }
  }
}

.child2D {
  display: flex;
  flex-direction: column;

  & .heading {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
  }

  & .child2DB {
    
    

    & .card {
      display: flex;
      
      flex-wrap: wrap;
      

      & .cardChild {
        flex-direction: column;
        flex: 1 0 45%;
        
        margin: 4px;

        & .title{
            color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
letter-spacing: 0.016px;
        }

        & .desc{

            color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: 0.016px;

        }
        
      }
    }
  }
}

.child2E{
    display: flex;
    flex-direction: column;

    & .heading{
        color: #000;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
    }

    & .desc{
        color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: 0.016px;
margin-bottom: 16px;
    }

    & .whyChoose{
        display: flex;
        margin-bottom: 16px;
        padding-left: 8px;
        

        & .whyChild1{
            
        }
    }
}
