.parentContainer {
    display: flex;
    padding: 24px;
  }
  
  .child1 {
    flex: 1 0 65%;
  
    & .child1A {
      & .child1Ai {
        display: flex;
        justify-content: space-between;
        border: 2px solid red;
  
        & .title {
          color: #28225e;
          font-family: Inter;
          font-size: 96px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration-line: underline;
          margin: 24px;
        }
  
        & img {
          margin-top: 32px;
          box-shadow: 30px 30px;
  
          object-fit: cover;
        }
      }
  
      & p {
        color: #000;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        padding: 24px;
      }
    }
  
    & .child1B {
      border: 5px solid;
      display: flex;
      flex-wrap: wrap;
  
      & .card {
        margin: 16px;
        padding: 16px;
        display: flex;
        flex: 1 0 45%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
        & .images {
          display: flex;
          place-items: center center;
          margin: 16px;
          img {
            width: 90px;
            border-radius: 50%;
            object-fit: cover;
            margin-top: 10px;
            border: 2px solid red;
          }
        }
  
        & .Carddesc {
          display: flex;
          flex-direction: column;
  
          & .title {
            color: #000;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
  
          & .desc {
            color: #000;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
  
  .child2 {
    flex: 1 0 35%;
  }









