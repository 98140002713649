/* .AboutusParent div img  {
  width: 100%;
} */

.AboutusParent {
  padding: 0;
  justify-content: center;
  display: flex;

  & .child {
    /* padding: 0 128px; */
    & .title1 {
      color: #ed3e23;
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      margin: 16px 0 0 0  ;
      font-family: Inter;

      & span {
        font-family: Roboto;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        
      }
    }

    & p {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 36px */
      padding-bottom: 16px;

      & span {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        
      }
    }

    & .title2 {
      color: #000;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      /* line-height: 150%; */
      text-transform: uppercase;
      color: #28225e;
    }

    & .testimonial {
      display: flex;
      flex-wrap: wrap;

      & .card {
        width: 300px;
        height: 400px;
        flex: 1 0 45%;
        padding: 16px;
        margin: 16px;
        background: #28225e;
        clip-path: polygon(
          0% 0%,
          100% 0%,
          100% 75%,
          75% 75%,
          75% 100%,
          50% 75%,
          0% 75%
        );
        color: white;
        padding: 16px;
        & img {
          width: 100px;
          height: 100px;
          margin: 0 100px;
          border-radius: 100px;
        }

        & h3 {
          text-align: center;
          margin-bottom: 8px;
        }

        & p {
          color: #000;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
        }
      }
    }
  }
}

p {
  justify-content: center;
}

.imgAdd {
  margin-bottom: 16px;
}

.imageWrap {
  display: flex;
  flex-wrap: nowrap;
}

.imageWrap div img {
  aspect-ratio: 1 / 1;
}

.jptitle {
}

/* Default styles for all screen sizes */
/* You can place your global styles here */

/* Media query for screens smaller than 768px (e.g., smartphones) */
@media screen and (max-width: 480px) {
  .AboutusParent {
    padding: 0;
    justify-content: center;

    & .child {
      padding: 16px;
      & .title1 {
        font-size: 24px;
      }

      & p {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        padding-bottom: 16px;

        & span {
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }

      & .title2 {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 36px */
      }

      & .testimonial {
        display: flex;
        flex-wrap: wrap;

        & .card {
          width: 300px;
          height: 400px;
          flex: 1 0 45%;
          padding: 16px;
          margin: 16px;
          background: #28225e;
          clip-path: polygon(
            0% 0%,
            100% 0%,
            100% 75%,
            75% 75%,
            75% 100%,
            50% 75%,
            0% 75%
          );
          color: white;
          padding: 16px;
          & img {
            width: 100px;
            height: 100px;
            margin: 0 100px;
            border-radius: 100px;
          }

          & h3 {
            text-align: center;
            margin-bottom: 8px;
          }

          & p {
            color: #000;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
          }
        }
      }
    }
  }
  .imgAdd {
  }

  .imageWrap {
    display: flex;
    flex-direction: column;
  }

  .imageWrap div img {
    aspect-ratio: 1 / 1;
  }
}

/* Media query for screens between 768px and 991px (e.g., tablets) */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .AboutusParent {
    padding: 0;
    justify-content: center;

    & .child {
      padding: 16px;
      & .title1 {
        font-size: 24px;
      }

      & p {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 36px */
        padding-bottom: 16px;

        & span {
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }

      & .title2 {
        color: #000;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */
      }

      & .testimonial {
        display: flex;
        flex-wrap: wrap;

        & .card {
          width: 300px;
          height: 400px;
          flex: 1 0 45%;
          padding: 16px;
          margin: 16px;
          background: #28225e;
          clip-path: polygon(
            0% 0%,
            100% 0%,
            100% 75%,
            75% 75%,
            75% 100%,
            50% 75%,
            0% 75%
          );
          color: white;
          padding: 16px;
          & img {
            width: 100px;
            height: 100px;
            margin: 0 100px;
            border-radius: 100px;
          }

          & h3 {
            text-align: center;
            margin-bottom: 8px;
          }

          & p {
            color: #000;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 30px */
          }
        }
      }
    }
  }
  .imgAdd {
  }

  .imageWrap {
    display: flex;
    flex-direction: column;
  }

  .imageWrap div img {
    aspect-ratio: 1 / 1;
  }
}

/* Media query for screens between 992px and 1199px (e.g., small desktops) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for large screens */
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media screen and (min-width: 1200px) {
  /* Styles for extra-large screens */
}
