.safetyParent {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.hero {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  & .title {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & .desc {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
}

.solutions {
  display: flex;
  flex-direction: column;
  
  margin-bottom: 24px;

  & .title {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 24px;
  }

  & .solution {
    display: flex;
    flex-wrap: wrap;

    & .solutionChild {
      flex: 1 0 45%;
      background: #fff;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
      margin: 8px;
      padding: 16px;

      & .title {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 24px */
      }

      & .desc {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
}

.risk {
  display: flex;
  flex-direction: column;
  padding: 24px;

  margin-bottom: 24px;

  & .title1 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 12px 0px;
  }

  & .desc {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  & .title2 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & .desc2 {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  & .riskCards {
    
    display: flex;
    margin-top: 40px;

    & .card {
      
      flex: 1 0 25%;
      display: inline-flex;
      padding: 15px 20px 12px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;
      background: #fff;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
      margin: 8px;
      

      & .cardHeading {
        
        display: flex;
        margin-bottom: 0;

        & .cardNumber {
          display: inline-flex;
          padding: 10px 23px 0 23px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #28225e;
          position: relative;
          margin-right: 8px;
          
          
          top: -48px;
          color: #FFF;
text-align: center;
font-family: Inter;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 96px */

        }

        & .cardTitle {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
        }
      }

      & .cardDesc {

        color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
margin-top: -40px;
      }
    }
  }

  & .riskFooter{
    margin-top: 16px;
    color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
  }
}
