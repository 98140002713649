.CardParent {
  width: 250px;
  margin: 8px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 4px;
  border-radius: 16px;
}

.CardParent div {
  position: relative;
}

.cardDesc{
  display:  flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cardDesc a{
  display: block;

}

.img11 {
  width: 100%;
  border-radius: 16px 16px 0 0 ;
}

.cardTitle {
  position: relative;
  font-size: 18px;
  font-family: Inter;
  color: #111013;
  text-align: left;
  padding-left: 12px;
  text-transform: capitalize;
  text-wrap: balance;
  text-align: center;
  margin-top: 16px;
  font-weight: 600;

}

.cardDescription {
  position: relative;
  font-size: 16px;
  font-family: Inter;
  color: #28225e;
  text-align: left;
  display: inline-flex;
  text-align: center;
  
  padding: 12px;
}

.explore {
  border: 1px solid #28225e;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 30px;
width: 50%;
  text-decoration: none;
  position: relative;
  bottom: 0px;
  left: 0;
text-align: center;
text-transform: capitalize;
}


.explore:hover {
  border: 1px solid #28225e;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 30px;
width: 50%;
  text-decoration: none;
  position: relative;
text-align: center;
text-transform: capitalize;
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(38,27,167,1) 100%);
color: #fff;
}

/* Default styles for all screen sizes */
/* You can place your global styles here */

/* Media query for screens smaller than 768px (e.g., smartphones) */
@media screen and (max-width: 480px) {
  .CardParent {
    width: 100%;
    margin: 8px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    padding-bottom: 4px;
  }
}

/* Media query for screens between 768px and 991px (e.g., tablets) */
@media screen and (min-width: 768px) and (max-width: 991px) {
  /* Styles for medium screens */
}

/* Media query for screens between 992px and 1199px (e.g., small desktops) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for large screens */
}

/* Media query for screens larger than 1200px (e.g., large desktops) */
@media screen and (min-width: 1200px) {
  /* Styles for extra-large screens */
}
