.container {
    display: flex;
    flex-direction: column;
  }
  
  .child1 {
    display: flex;
    flex-direction: column;
    margin: 24px;
  
    & .Child1Heading {
      text-align: center;
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    & .child1Card {
      display: flex;
      flex-direction: row;
    }
  
    & .desc,
    .child1Img {
      flex: 1 0 50%;
      text-align: justify;
      padding: 5px;
    }
  }
  
  .cardsParent {
    display: flex;
    flex-direction: column;
    margin: 24px;
  
    & .cardHeading {
      text-align: center;
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
  
    & .cards {
      display: flex;
      flex-wrap: wrap;
  
      & .card {
        display: flex;
        flex-direction: column;
  
        flex: 1 0 30%;
        margin: 5px;
        background: #fff;
        box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  
        & .cardTitle {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
  
        & .cardDesk {
          color: #000;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
    }
  }
  
  .ServicecardsParent {
    display: flex;
    flex-direction: column;
    margin: 24px;
  
    & .Servicecards {
      display: flex;
      flex-direction: row;
  
      flex-wrap: wrap;
    }
  
    & .ServicecardHeading {
      text-align: center;
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 24px;
    }
  
    & .Servicecard {
      display: flex;
      flex-direction: row;
  
      flex: 1 0 48%;
      flex-wrap: wrap;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 8px;
    }
  
    & .ServicecardImg {
      flex: 1 0 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
  
      & img {
        border-radius: 50%;
        background-color: #d9d9d9;
      }
    }
  
    & .ServiceDesc {
      flex: 1 0 80%;
      flex-direction: column;
      padding: 24px;
  
      & .ServicecardTitle {
        color: #ed3e23;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
      }
  
      & .ServicecardDesk {
        color: #000;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
      }
    }
  }
  
  .contact {
    display: flex;
    flex-direction: column;
  
    & p {
      text-align: center;
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0px;
    }
  
    & .contactDesk {
      display: flex;
  
      & .desc {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin: 24px;
      }
  
      & div button {
        border-radius: 30px;
        background: #28225e;
        display: inline-flex;
        height: 40px;
        padding: 11px 34px 10px 36px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin: 24px;
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  